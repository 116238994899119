<template>

    <div class="starter-template">



        <h1>Bootstrap starter template</h1>
        <p class="lead">Use this document as a way to quickly start any new project.<br> All you get is this text and a mostly barebones HTML document.</p>

        <hr>



    </div>
</template>

<script>

</script>


<style>

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}
</style>
